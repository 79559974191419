/**
 * Created by slava on 23.03.17.
 */
ComingSoonLocationView.prototype.customize = cleverapps.extendFunc(ComingSoonLocationView.prototype.customize, function () {
    var styles = cleverapps.styles.LocationView.coming_soon;

    this.activities = [];

    this.addCup();
    if (!connector.platform.oneOf(connector.INSTANT, connector.YOUTUBE, connector.GAMESNACK)) {
        this.addJoin();
    }

    var activitiesLayout = new cleverapps.Layout(this.activities, {
        direction: styles.activities.direction,
        reversed: styles.activities.direction === cleverapps.UI.VERTICAL,
        margin: styles.activities.margin
    });
    activitiesLayout.setScale(styles.activities.scale[cleverapps.resolution.mode]);

    var items = [];
    items.unshift(activitiesLayout);

    var text = this.createText();
    if (text) {
        items.unshift(text);
    }

    if (this.animation) {
        items.unshift(this.animation);
        this.animation.setScale(styles.animation.scale);
        this.animation.removeFromParent();
    }

    this.addAnimationText();

    var layout = this.content = new cleverapps.Layout(items, {
        direction: cleverapps.UI.VERTICAL,
        margin: styles.margin
    });

    if (this.animation) {
        this.animation.setAnimation(0, "animation", true);
    }

    layout.setScale(styles.scale);

    this.addChild(layout);
    if (styles.positions) {
        layout.setPositionRound(styles.positions[cleverapps.resolution.mode]);
    }
});

ComingSoonLocationView.prototype.addAnimationText = function () {
    var styles = cleverapps.styles.LocationView.coming_soon;

    if (!styles.animation.text || !this.animation) {
        return;
    }

    var text = cleverapps.UI.generateTTFText("EpisodeView.ComingSoonEpisode.animationText", cleverapps.styles.FONTS.EPISODE_CLOUD_TEXT);
    text.setDimensions(styles.animation.text.width, 0);
    text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
    this.animation.addChild(text);
    text.setPositionRound(styles.animation.text);

    var scale = styles.animation.text.height / text.height;
    if (scale < 1) {
        text.setScale(scale);
    }
};

ComingSoonLocationView.prototype.createText = function () {
    var styles = cleverapps.styles.LocationView.coming_soon;

    var msg = this.activities.length > 0 ? styles.text.activitiesMsg : styles.text.noActivitiesMsg;
    if (!msg) {
        return;
    }
    var text = cleverapps.UI.generateTTFText(msg, styles.font);
    text.setDimensions(styles.text.width[cleverapps.resolution.mode] * resolutionScale, 0);
    text.setScale(styles.text.scale[cleverapps.resolution.mode]);
    text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

    return text;
};

ComingSoonLocationView.prototype.createButton = function (text, onClicked) {
    var styles = cleverapps.styles.LocationView.coming_soon;

    return new cleverapps.UI.Button({
        text: text,
        width: styles.activities.button.width,
        height: styles.activities.button.height,
        onClicked: onClicked
    });
};

ComingSoonLocationView.prototype.createActivityText = function (text) {
    var styles = cleverapps.styles.LocationView.coming_soon;

    text = cleverapps.UI.generateTTFText(text, styles.font);
    text.setDimensions(styles.activities.text.width, styles.activities.text.height);
    text.setHorizontalAlignment(styles.activities.text.horizontalAlignment);
    text.setVerticalAlignment(styles.activities.text.verticalAlignment);
    return text;
};

ComingSoonLocationView.prototype.createAnimation = function (json) {
    var styles = cleverapps.styles.LocationView.coming_soon;
    if (!styles.activities.animation) {
        return;
    }

    var animation = new cleverapps.Spine(json);
    animation.setAnimation(0, "animation", true);
    return animation;
};

ComingSoonLocationView.prototype.addCup = function () {
    if (!cleverapps.dailyCup || !cleverapps.dailyCup.isRunning()) {
        return;
    }
    var styles = cleverapps.styles.LocationView.coming_soon;

    var items = [];

    var text = this.createActivityText("EpisodeView.ComingSoonEpisode.cup");
    items.push(text);

    var button = this.createButton(styles.activities.text.cupMsg, function () {
        cleverapps.focusManager.display({
            focus: "DailyCupSceneFromComingSoon",
            action: function (f) {
                cleverapps.scenes.replaceScene(new DailyCupScene(), f);
            }
        });
    });
    items.push(button);

    var animation = this.createAnimation(bundles.episode_coming_soon.jsons.cup_json);
    if (animation) {
        items.push(animation);
    }

    this.addActivity(items);
};

ComingSoonLocationView.prototype.addJoin = function () {
    if (connector.platform.oneOf(connector.AMAZON, connector.PLIEGA)) {
        return;
    }
    var styles = cleverapps.styles.LocationView.coming_soon;

    var items = [];

    var text = this.createActivityText("EpisodeView.ComingSoonEpisode.join");
    items.push(text);

    var button = this.createButton(styles.activities.text.joinMsg, function () {
        connector.social.joinCommunityCallback();
    });
    items.push(button);

    var animation = this.createAnimation(bundles.episode_coming_soon.jsons.join_json);
    if (animation) {
        items.push(animation);
    }

    this.addActivity(items);
};

ComingSoonLocationView.prototype.addActivity = function (items) {
    var styles = cleverapps.styles.LocationView.coming_soon;

    this.activities.push(new cleverapps.Layout(items, {
        direction: styles.activities.itemsDirection,
        reversed: styles.activities.itemsDirection === cleverapps.UI.VERTICAL,
        margin: styles.activities.itemsMargin
    }));
};

cleverapps.overrideStyles(cleverapps.styles.LocationView, {
    coming_soon: {
        animation: {
            scale: 1.0
        },
        activities: {
            margin: 30,
            itemsMargin: 10,
            text: {
                width: 380,
                horizontalAlignment: cc.TEXT_ALIGNMENT_CENTER,
                verticalAlignment: cc.VERTICAL_TEXT_ALIGNMENT_TOP,
                cupMsg: "Compete",
                joinMsg: "Join"
            },
            button: {
                width: 380
            },
            animation: true,
            scale: [0.8, 1.0, 1.0],
            itemsDirection: cleverapps.UI.VERTICAL,
            direction: cleverapps.UI.HORIZONTAL
        },
        margin: 40,
        text: {
            width: [380, 550, 550],
            scale: [0.9, 1.0, 1.0],
            activitiesMsg: "EpisodeView.ComingSoonEpisode.activities",
            noActivitiesMsg: "EpisodeView.ComingSoonEpisode"
        },
        scale: 1.0,
        font: cleverapps.styles.FONTS.EPISODE_CLOUD_TEXT
    }
});
