/**
 * Created by anatoly on 10.10.2024
 */

var DangerButtonWindow = CleverappsWindow.extend({
    ctor: function (outcome) {
        this._super();

        this.outcome = outcome;
        this.needCloseAnimation = false;
    },

    onWindowLoaded: function () {
        var isFail = this.outcome === GameBase.OUTCOME_LOST || this.outcome === GameBase.OUTCOME_GAVEUP;

        this._super({
            content: this.createContent(),
            noBackground: true,
            notCloseByTouchInShadow: !isFail,
            closeButton: isFail
        });

        this.showAnimation();
        if (!isFail) {
            this.enableTouchInButton();
        }
    },

    addPressButtonText: function () {
        var styles = cleverapps.styles.DangerButtonWindow.text;

        var text = cleverapps.UI.generateOnlyText("DangerButtonWindow.PressButton", cleverapps.styles.FONTS.CLOSE_TEXT);
        text.setDimensions(styles.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        text.setPositionRound(styles);

        this.addChild(text);
    },

    createContent: function () {
        this.animation = new cleverapps.Spine(bundles.danger_button.jsons.button_json);

        return this.animation;
    },

    showAnimation: function () {
        if (this.outcome === GameBase.OUTCOME_VICTORY) {
            this.animation.setAnimationAndIdleAfter("popup_stop", "idle_stop");
            this.showButtonAnimation(DangerButtonWindow.TYPE_STOP);
            this.addPressButtonText();

            this.needCloseAnimation = true;
        } else if (this.outcome === GameBase.OUTCOME_LOST || this.outcome === GameBase.OUTCOME_GAVEUP) {
            this.showButtonAnimation(DangerButtonWindow.TYPE_FAIL);
        }
    },

    enableTouchInButton: function () {
        cleverapps.UI.onClick(this.animation, this.close.bind(this), {
            interactiveScale: false
        });
    },

    showButtonAnimation: function (type) {
        var buttonText = this.buttonText = cleverapps.UI.generateOnlyText("DangerButtonWindow." + type, cleverapps.styles.FONTS.DANGER_BUTTON_TEXT);

        buttonText.setPosition(this.animation.width / 2, this.animation.height / 2);
        buttonText.fitTo(cleverapps.styles.DangerButtonWindow.buttonText.width);
        this.animation.addChild(buttonText);

        switch (type) {
            case DangerButtonWindow.TYPE_STOP:
                var basePosition = this.animation.getPosition();

                this.animation.setVisible(false);

                var startAnimationPosition = cc.p(this.width / 2 + this.animation.width, this.height / 2);
                this.animation.setPosition(this.animation.getParent().convertToNodeSpace(startAnimationPosition));

                var startPoint = this.animation.getPosition();
                var midPoint = cc.p((basePosition.x + startPoint.x) / 2, startPoint.y);

                this.animation.runAction(new cc.Sequence(
                    new cc.DelayTime(0.05),
                    new cc.Show(),
                    new cc.BezierTo(0.4, [startPoint, midPoint, basePosition]).easing(cc.easeOut(3))
                )).setFinalize(function () {
                    this.animation.setPosition(basePosition);
                }.bind(this));

                buttonText.setScale(0.6);
                buttonText.runAction(new cc.ScaleTo(0.45, 1));

                break;
            case DangerButtonWindow.TYPE_FAIL:
                this.animation.runAction(new cc.Sequence(
                    new cc.DelayTime(0.5),
                    new cc.CallFunc(function () {
                        this.animation.setAnimationAndIdleAfter("fail", "idle_fail");
                        cleverapps.audio.playSound(bundles.danger_component.urls.tricky_lose);
                    }.bind(this))
                ));

                buttonText.setVisible(false);
                buttonText.runAction(new cc.Sequence(
                    new cc.DelayTime(0.55),
                    new cc.CallFunc(function () {
                        buttonText.setVisible(true);

                        buttonText.setScale(1.7);
                        buttonText.runAction(new cc.Sequence(
                            new cc.ScaleTo(0.135, 1),
                            new cc.ScaleTo(0.155, 1.15),
                            new cc.ScaleTo(0.155, 1)
                        ));
                    })
                ));
                break;
        }
    },

    beforeCloseAnimation: function (callback) {
        if (this.needCloseAnimation) {
            this.closeButtonAnimation(callback);
            return;
        }

        callback();
    },

    closeButtonAnimation: function (callback) {
        var animation = this.animation;

        animation.setAnimation(0, "push_stop", false);
        this.buttonText.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.ScaleTo(0.1, 0.9)
            ),
            new cc.Spawn(
                new cc.ScaleTo(0.1, 1)
            )
        ));
        animation.setCompleteListener(function () {
            this.buttonText.runAction(new cc.Sequence(
                new cc.DelayTime(0.2),
                new cc.FadeOut(0.1)
            ));
            animation.addAnimation(0, "fadeout_stop", false);

            animation.setCompleteListener(callback);
        }.bind(this));
    },

    listBundles: function () {
        return ["danger_button"];
    }
});

DangerButtonWindow.TYPE_STOP = "Stop";
DangerButtonWindow.TYPE_FAIL = "Fail";

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    DANGER_BUTTON_TEXT_SHADOW: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_SHADOW_COLOR,
        direction: cc.size(0, -8),
        blur: 12
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    DANGER_BUTTON_TEXT: {
        size: 100,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.DANGER_BUTTON_TEXT_SHADOW
    }
});

cleverapps.styles.DangerButtonWindow = {
    text: {
        width: 640,

        x: { align: "center" },
        y: { align: "bottom", dy: 60 }
    },

    buttonText: {
        width: 230
    }
};