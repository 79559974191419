/**
 * Created by anatoly on 28.08.2024
 */

var DangerAnimationView = cc.Node.extend({
    ctor: function (component) {
        this._super();

        this.component = component;

        this.updateSize();

        this.addHero();
        this.addBoerAnimation();

        this.setupChildren();

        component.on("onStart", this.startAnimation.bind(this));
    },

    addBoerAnimation: function () {
        var styles = cleverapps.styles.BoerAnimation;

        this.boer = new BoerAnimation(this, this.component);

        this.boer.setPositionRound(styles);
        this.addChild(this.boer);
    },

    addHero: function () {
        var styles = cleverapps.styles.DangerAnimation.hero;

        var person = cleverapps.persons.getRole(styles.personCode);
        var hero = this.hero = new cleverapps.Spine(person.json);
        hero.setAnimation(0, "idle_tricky", true);

        this.component.on("onNoMuchTime", function () {
            hero.setAnimation(0, "scared", true);
            cleverapps.audio.playSound(bundles.danger_component.urls.tricky_vox_no);
        });

        this.addChild(hero, -1);
    },

    startHeroAnimation: function () {
        this.hero.setAnimationAndIdleAfter("start", "idle_tricky");
        cleverapps.audio.playSound(bundles.danger_component.urls.tricky_help);
    },

    startAnimation: function () {
        this.startHeroAnimation();
        this.boer.startAnimation();
    },

    onWin: function () {
        this.boer.setBoersStopAnimation();
        this.hero.setAnimation(0, "joy", true);

        this.hero.runAction(new cc.Sequence(
            new cc.PlaySound(bundles.danger_component.urls.tricky_applause),
            new cc.DelayTime(2),
            new cc.CallFunc(function () {
                this.removeHero();
            }.bind(this))
        ));
    },

    onLose: function () {
        this.hero.setAnimation(0, "disappear", false);
        this.hero.runAction(new cc.Sequence(
            new cc.DelayTime(2),
            new cc.CallFunc(function () {
                this.removeHero();
            }.bind(this))
        ));
    },

    removeHero: function () {
        this.hero.runAction(new cc.Sequence(
            new cc.MoveTo(0.4, cc.p((-this.hero.width * this.hero.scale) / 2, this.hero.y)).easing(cc.easeIn(2)),
            new cc.RemoveSelf()
        ));
    },

    freeze: function () {
        this.boer.setTimeScale(0);
        this.hero.setTimeScale(0);
    },

    unfreeze: function () {
        this.hero.setTimeScale(1);
        this.boer.setTimeScale(1);
    },

    getPartFromScreen: function () {
        return cleverapps.styles.DangerAnimation.partFromScreen[cleverapps.resolution.mode];
    },

    updateSize: function () {
        var styles = cleverapps.styles.DangerAnimation;

        var rect = cleverapps.resolution.getBgRect();
        var partFromScreen = this.getPartFromScreen();

        var position = styles.positions[cleverapps.resolution.mode];

        if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            var totalWidth = rect.width;

            position.y.dy = rect.y / 2;
            this.setContentSize(totalWidth, rect.height);
        } else {
            var totalHeight = rect.height * partFromScreen;

            this.setContentSize(rect.width, totalHeight);
        }
    },

    setupChildren: function () {
        var styles = cleverapps.styles.DangerAnimation;

        var position = styles.positions[cleverapps.resolution.mode];

        this.setPositionRound(position);

        this.hero.setScale(styles.scales[cleverapps.resolution.mode]);
        this.hero.setPositionRound(styles.hero.positions[cleverapps.resolution.mode]);

        this.boer.onAnimationResize();
    }
});

cleverapps.styles.DangerAnimation = {
    partFromScreen: [0.4, 0.4, 0.5],

    positions: [
        {
            x: { align: "center" },
            y: { align: "top" }
        },
        {
            x: { align: "center" },
            y: { align: "top" }
        },
        {
            x: { align: "left" },
            y: { align: "center" }
        }
    ],

    scales: [0.5, 0.5, 1],

    hero: {
        personCode: "yellow",
        positions: [
            {
                x: { align: "left", dx: 60 },
                y: { align: "center" }
            },
            {
                x: { align: "left", dx: 60 },
                y: { align: "center" }
            },
            {
                x: { align: "left", dx: 100 },
                y: { align: "center", dy: -220 }
            }
        ]

    }
};