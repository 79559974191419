/**
 * Created by anatoly on 09.09.2024
 */

var BoerAnimation = cc.Node.extend({
    ctor: function (animationView, component) {
        this._super();
        this.component = component;
        this.animationView = animationView;

        this.addBlockWall();
        this.addBoers();

        this.onAnimationResize();

        component.on("onStop", this.stop.bind(this));
        component.on("onStart", function () {
            this.runAction(new cc.RepeatForever(
                new cc.Sequence(
                    new cc.CallFunc(function () {
                        this.boerSound = cleverapps.audio.playSound(bundles.danger_component.urls.tricky_boer);
                    }.bind(this)),
                    new cc.DelayTime(8)
                )
            ));
        }.bind(this));
    },

    startAnimation: function () {
        if (this.component.stopped) {
            return;
        }

        var timeLeft = this.component.getTimeLeft();

        this.wall.runAction(new cc.MoveTo(timeLeft / 1000, -this.getEndPosition(), this.wall.y));
    },

    onAnimationResize: function () {
        this.stopAnimation();

        this.updateWall();

        if (this.needRecreate()) {
            this.wideMode = cleverapps.resolution.mode;
            this.updateBoers();
        }

        var percentOfCompletion = this.component.getPercentOfCompletion();
        var endPosition = this.getEndPosition();

        var offset = 0;
        if (this.wideMode === cleverapps.WideMode.HORIZONTAL) {
            var partFromScreen = this.animationView.getPartFromScreen();
            offset = this.animationView.width * (1 - partFromScreen) + this.wall.width / 2;
        }

        endPosition -= offset;

        this.wall.setPosition(-endPosition * percentOfCompletion - offset, this.wall.y);

        if (this.component.started) {
            this.startAnimation();
        }
    },

    getEndPosition: function () {
        var hero = this.animationView.hero;

        return this.animationView.width
            - hero.getPosition().x
            - hero.width / 2 * hero.scale
            - this.wall.width / 2 * this.wall.scale
            - this.boersLayout.width * this.wall.scale;
    },

    needRecreate: function () {
        return this.wideMode !== cleverapps.resolution.mode;
    },

    addBlockWall: function () {
        this.wall = new cc.Sprite(bundles.danger_component.frames.block);

        this.updateWall();
        this.addChild(this.wall, 1);
    },

    updateWall: function () {
        this.wall.setScale(this.animationView.height / this.wall.height);
    },

    addBoers: function () {
        this.boers = [];
        for (var i = 0; i < BoerAnimation.BOERS_COUNT; i++) {
            var boer = new cleverapps.Spine(bundles.danger_component.jsons.boer);
            boer.setAnimation(0, "idle", true);

            this.boers.push(boer);
        }

        this.boersLayout = new cleverapps.Layout(this.boers);
        this.updateBoers();

        this.wall.addChild(this.boersLayout);
    },

    updateBoers: function () {
        var styles = cleverapps.styles.BoerAnimation.boers;

        var boers = this.boersLayout;

        boers.setOptions({
            margin: styles.margin[cleverapps.resolution.mode]
        });

        boers.setPositionRound(styles);
    },

    setTimeScale: function (timeScale) {
        this.boers.forEach(function (boer) {
            boer.setTimeScale(timeScale);
        });
    },

    stopAnimation: function () {
        this.wall.stopAllActions();
    },

    setBoersStopAnimation: function () {
        this.boers.forEach(function (boer) {
            boer.setAnimation(0, "stop");
        });

        cleverapps.audio.playSound(bundles.danger_component.urls.tricky_boer_win);
    },

    stop: function () {
        this.stopAnimation();
        this.stopAllActions();

        cleverapps.audio.stopSound(this.boerSound);
    }
});

BoerAnimation.BOERS_COUNT = 4;

cleverapps.styles.BoerAnimation = {
    x: { align: "right" },
    y: { align: "center" },

    boers: {
        x: { align: "center", dx: -80 },
        y: { align: "center", dy: 50 },

        margin: [25, 25, 60]
    }
};