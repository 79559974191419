/**
 * Created by anatoly on 06.09.2024
 */

var DangerComponentView = cc.Node.extend({
    ctor: function (component) {
        this._super();

        var styles = cleverapps.styles.DangerComponentView;

        this.component = component;

        this.addTimer();
        this.addAnimation();

        this.setContentSize(styles);

        this.setPositionRound(cleverapps.styles.DangerComponentView);

        component.on("onStop", this.stop.bind(this));
    },

    addAnimation: function () {
        var styles = cleverapps.styles.DangerComponentView.animation;

        var animation = this.animation = new cleverapps.Spine(bundles.danger_component.jsons.timer);

        animation.setAnimation(0, "animation");
        animation.setPositionRound(styles);

        var animationDuration = animation.getAnimationDuration("animation");
        animation.setTimeScale(0);
        this.component.on("onStart", function () {
            var realDuration = this.component.getTimeLeft() / 1000;
            var timeScale = animationDuration / realDuration;
            animation.setTimeScale(timeScale);
        }.bind(this));

        this.addChild(animation);
    },

    addTimer: function () {
        var styles = cleverapps.styles.DangerComponentView.timer;

        var timerBg = cleverapps.UI.createScale9Sprite(bundles.danger_component.frames.timer_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);

        timerBg.setContentSize(styles.background.width, timerBg.height);
        timerBg.setPositionRound(styles);

        this.addChild(timerBg, -1);

        this.timerView = cleverapps.UI.generateImageText("00:00", cleverapps.styles.FONTS.DANGER_COMPONENT);
        this.updateTime();

        this.component.on("update", this.updateTime.bind(this));

        this.timerView.setPositionRound(styles.text);

        this.addChild(this.timerView);
    },

    updateTime: function () {
        var timeLeft = this.component.getTimeLeft();
        this.timerView.setString(cleverapps.intervalToString(timeLeft, false));
    },

    stop: function () {
        this.animation.setTimeScale(0);
    }
});

cleverapps.styles.DangerComponentView = {
    width: 270,
    height: 100,

    x: { align: "left", dx: 20 },
    y: { align: "center", dy: 5 },

    timer: {
        x: { align: "center", dx: 30 },
        y: { align: "center" },

        background: {
            width: 220
        },

        text: {
            x: { align: "center", dx: 55 },
            y: { align: "center", dy: -4 }
        }
    },

    animation: {
        x: { align: "left" },
        y: { align: "center" }
    }
};

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    DANGER_COMPONENT: {
        name: "nostroke",
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        size: 45
    }
});