/**
 * Created by mac on 5/6/17.
 */

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    EDITOR_RED_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_RED
    }
});

cleverapps.overrideStyles(cleverapps.styles.FieldBoostersView, {
    position: [
        { x: { align: "center", dx: -47 }, y: { align: "center", dy: 10 } },
        { x: { align: "center", dx: -3 }, y: { align: "center", dy: 10 } },
        { x: { align: "center", dx: -3 }, y: { align: "center", dy: 10 } }
    ],

    sizes: [
        {
            width: 590,
            height: 210
        },
        {
            width: 190,
            height: 650
        },
        {
            width: 190,
            height: 650
        }
    ]
});

cleverapps.styles.UI.BoostersPositions = [
    [
        { x: { align: "center", dx: -110 }, y: { align: "center", dy: -8 } },
        { x: { align: "center", dx: 48 }, y: { align: "center", dy: -8 } },
        { x: { align: "center", dx: 206 }, y: { align: "center", dy: -8 } }
    ],
    [
        { x: { align: "center" }, y: { align: "center", dy: 160 } },
        { x: { align: "center" }, y: { align: "center", dy: -5 } },
        { x: { align: "center" }, y: { align: "center", dy: -170 } }
    ],
    [
        { x: { align: "center" }, y: { align: "center", dy: 160 } },
        { x: { align: "center" }, y: { align: "center", dy: -5 } },
        { x: { align: "center" }, y: { align: "center", dy: -170 } }
    ]
];

cleverapps.override(cleverapps.styles.LevelView, {
    hidableLevelNo: true
});

cleverapps.overrideStyles(cleverapps.styles.LevelsView, {
    betweenEpisodesFlag: {
        spine: bundles.main.jsons.between_episodes_json,
        passed: "flag2",
        notpassed: "flag1",

        levels_1: {
            default: {
                x: 245,
                y: 690
            }
        },

        levels_2: {
            default: {
                x: -260,
                y: 690
            }
        }
    },

    levels_1: [
        { x: -210, y: -490 },
        { x: -84, y: -428 },
        { x: 59, y: -382 },
        { x: 186, y: -306 },
        { x: 254, y: -190 },
        { x: 220, y: -64 },
        { x: 96, y: 2 },
        { x: -40, y: 26 },
        { x: -170, y: 76 },
        { x: -256, y: 174 },
        { x: -220, y: 282 },
        { x: -106, y: 350 },
        { x: 28, y: 388 },
        { x: 158, y: 434 },
        { x: 244, y: 530 }
    ],
    levels_2: [
        { x: 210, y: -490 },
        { x: 80, y: -422 },
        { x: -60, y: -376 },
        { x: -188, y: -298 },
        { x: -256, y: -180 },
        { x: -220, y: -56 },
        { x: -104, y: 8 },
        { x: 36, y: 32 },
        { x: 164, y: 80 },
        { x: 254, y: 184 },
        { x: 200, y: 306 },
        { x: 72, y: 364 },
        { x: -60, y: 404 },
        { x: -184, y: 456 },
        { x: -256, y: 546 }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.BaseGoalView, {
    width: 100,
    height: 120,
    scaleX: -0.85,
    scaleY: 0.85,

    icon: {
        x: 0,
        y: 0
    },

    amount: {
        x: 37,
        y: 30
    },

    withTournament: {
        width: 60,
        height: 100,
        scaleX: -0.75,
        scaleY: 0.75,

        icon: {
            x: 0,
            y: 0
        },

        amount: {
            x: 20,
            y: 28
        }
    },

    no_moves: {
        width: 100,
        height: 120,
        scaleX: -1,
        scaleY: 1,

        icon: {
            x: 0,
            y: 0
        },

        amount: {
            x: 54,
            y: -10,
            scale: 1.3
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.GoalsView, {
    bgTitle: {
        text: {
            offsetY: 2
        },
        position: [{
            x: { align: "center" },
            y: { align: "top", dy: 13 }
        }, {
            x: { align: "center" },
            y: { align: "top", dy: 13 }
        }, {
            x: { align: "center" },
            y: { align: "top", dy: 13 },
            scale: 0.8
        }]
    },

    positionNoMission: [{
        x: { align: "center", dx: 77 },
        y: { align: "center", dy: 15 }
    }, {
        x: { align: "center", dx: 77 },
        y: { align: "center", dy: 15 }
    }, {
        x: { align: "center", dx: -20 },
        y: { align: "center", dy: -85 }
    }],

    positionWithMission: [{
        x: { align: "center", dx: 10 },
        y: { align: "center", dy: 15 }
    }, {
        x: { align: "center", dx: 10 },
        y: { align: "center", dy: 15 }
    }, {
        x: { align: "center", dx: -17 },
        y: { align: "center", dy: -25 }
    }],

    positionDangerLevel: [
        {
            x: { align: "right", dx: -20 },
            y: { align: "center", dy: 5 }
        }, {
            x: { align: "right", dx: -20 },
            y: { align: "center", dy: 5 }
        }, {
            x: { align: "right", dx: -20 },
            y: { align: "center", dy: 5 }
        }
    ],

    sizes: {
        noMission: [{
            height: 133,
            width: 480
        }, {
            height: 133,
            width: 480
        }, {
            height: 440,
            width: 135
        }],

        withMission: [{
            height: 125,
            width: 350
        }, {
            height: 125,
            width: 350
        }, {
            height: 330,
            width: 125
        }]
    },

    startAnimation: {
        width: 400,

        action: { delay: 0.3, scale: 0.4 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UI.MovesView, {
    scale: [1, 1, 1],

    text: {
        margin: { x: 0, y: 12 }
    },

    icon: {
        x: { align: "center" },
        y: -36
    }
});

cleverapps.overrideStyles(cleverapps.styles.MissionAnimationView, {
    flyTo: {
        second: {
            y: 15
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PagingScrollView, {
    maxWidth: 1500,
    comingSoonExtraLength: [-90, -40, 60],
    position: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 0 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    animation: {
        "starterPack0": {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        },

        "starterPack": {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        },

        "starterPack2": {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        }
    },

    rewards: {
        positions: {
            "default": {
                "boosters": {
                    "5": {
                        x: -240,
                        y: -15
                    },
                    "6": {
                        x: 250,
                        y: -15
                    },
                    "7": {
                        x: 280,
                        y: -370
                    }
                },
                "unlimitedLives": {
                    x: -270,
                    y: -370
                },
                "hard": {
                    x: 4,
                    y: -245
                }
            },

            starterPack0: undefined,
            starterPack: undefined,
            starterPack2: undefined

        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.CanUpgradeHeroWindow, {
    heroes: [{
        x: -70,
        y: 136,
        scale: 1.1
    }, {
        x: 70,
        y: 136,
        scale: 1.1
    }, {
        x: -150,
        y: 50,
        scale: 1.25
    }, {
        x: 150,
        y: 50,
        scale: 1.25
    }, {
        x: -76,
        y: -50,
        scale: 1.4
    }, {
        x: 76,
        y: -50,
        scale: 1.4
    }]
});

cleverapps.overrideStyles(cleverapps.styles.LevelPassWindow, {
    "tickets": {
        x: { align: "left", dx: 35 },
        y: { align: "top", dy: -244 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTaskView, {
    reward: {
        margin: {
            x: 10
        },
        textMargin: 10
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassProgress, {
    paddingX: 142
});

cleverapps.overrideStyles(cleverapps.styles.PassLastChanceWindow, {
    rewards: {
        ticket: {
            x: { align: "right", dx: 90 },
            y: { align: "bottom", dy: -75 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LoaderSceneBackground, {
    logoAnimation: {
        pos: {
            factorY: 0.8
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PlayButtonView, {
    position: [
        {
            x: { align: "center" },
            y: { align: "bottom", dy: 150 }
        },
        {
            x: { align: "center" },
            y: { align: "bottom", dy: 150 }
        },
        {
            x: { align: "center" },
            y: { align: "bottom", dy: 173 }
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.GameScene.STYLING, {
    tricky: {
        bundle: "danger_component",
        music: bundles.danger_component.urls.music_game
    }
});